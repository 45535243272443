import React, { useContext, useEffect } from 'react';
import HorizontalFieldValue from '../../components/Forms/HorizontalFieldValue';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ApiContext } from '../../../../api/api';
import { useAuth0 } from '@auth0/auth0-react';

interface ComponentProps {
  onSaved: () => void;
}

interface FormData {
  fullName: string;
  email: string;
}

const initialData: FormData = {
  fullName: '',
  email: '',
};

const validationSchema = yup.object().shape({
  fullName: yup.string().required(),
});

const UpdateUserName: React.FC<ComponentProps> = ({ onSaved }) => {
  const { user } = useAuth0();

  const { updateUserFullName } = useContext(ApiContext);

  const formik = useFormik<FormData>({
    initialValues: initialData,
    validationSchema,
    onSubmit: () => {
      updateUserFullName({ fullName: formik.values.fullName }, () => {
        onSaved();
      });
    },
  });

  useEffect(() => {
    formik.setFieldValue('email', user.email);
  }, [user]);

  return (
    <>
      <CenterTile>
        <RegistrationWrapper>
          <h1>Tell us who are you</h1>
          <form onSubmit={formik.handleSubmit}>
            <NameAndEmailRow>
              <HorizontalFieldValue
                label={'Full name'}
                value={
                  <>
                    <InputText
                      style={{ width: '100%' }}
                      value={formik.values.fullName}
                      onChange={formik.handleChange}
                      name={'fullName'}
                    />
                    <Error>{formik.errors.fullName}</Error>
                  </>
                }
              />
              <HorizontalFieldValue
                label={'E-mail'}
                value={
                  <InputText
                    style={{ width: '100%' }}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    name={'email'}
                    readOnly={true}
                    disabled={true}
                  />
                }
              />
            </NameAndEmailRow>
            <CenteredRow>
              <Button label={'Save'} />
            </CenteredRow>
          </form>
        </RegistrationWrapper>
      </CenterTile>
    </>
  );
};

const Error = styled.div`
  display: flex;
  color: red;
  margin-top: 0.3rem;
`;

const CenteredRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const NameAndEmailRow = styled.div`
  margin-top: 1rem;
  display: flex;
  column-gap: 1rem;
`;

const RegistrationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 45rem;

  margin: 0 auto;
  background-color: #fafafa;
  padding: 2rem;
`;

const CenterTile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export default UpdateUserName;
