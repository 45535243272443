import React, { useContext, useEffect } from 'react';
import HorizontalFieldValue from '../../components/Forms/HorizontalFieldValue';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAuth0 } from '@auth0/auth0-react';
import { ApiContext } from '../../../../api/api';

interface ComponentProps {}

interface FormData {
  fullName: string;
  email: string;
}

const initialData: FormData = {
  fullName: '',
  email: '',
};

const validationSchema = yup.object().shape({});

const UpdateUserName: React.FC<ComponentProps> = () => {
  const { loginWithPopup, loginWithRedirect } = useAuth0();

  const login = () => {
    loginWithPopup();
  };
  return (
    <>
      <CenterTile>
        <RegistrationWrapper>
          <h1>Login or register</h1>
          <ButtonInner>
            <Button label={'Login'} onClick={(e) => login()} />
          </ButtonInner>
        </RegistrationWrapper>
      </CenterTile>
    </>
  );
};

const ButtonInner = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const NameAndEmailRow = styled.div`
  margin-top: 1rem;
  display: flex;
  column-gap: 1rem;
`;

const RegistrationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 45rem;

  margin: 0 auto;
  background-color: #fafafa;
  padding: 2rem;
`;

const CenterTile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export default UpdateUserName;
