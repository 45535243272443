import React, { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { dumpVars } from '../lib/debug';
import LoginOrRegister from '../components/admin/pages/Auth/LoginOrRegister';
import { ApiContext } from '../api/api';
import { StageEnum, UserLoginInfoOutDTO } from '../api/frontend-service.v1';
import AdminLayout from '../components/admin/components/Layout/AdminLayout';
import UpdateUserName from '../components/admin/pages/Auth/UpdateUserName';
import { isNotNullOrUndefined } from '../lib/utils';
import useNavigation, { Page } from '../hooks/useNavigation';
import { UserContext } from '../components/admin/security/UserProvider';

interface ComponentProps {}

const IndexPage: React.FC<ComponentProps> = () => {
  const { navigate } = useNavigation();
  const { user } = useAuth0();
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const { hasUserIdentity } = useContext(ApiContext);
  const { userInfoWithPermissions } = useContext(UserContext);
  const [stage, setStage] = useState<UserLoginInfoOutDTO>({ stage: null });

  useEffect(() => {
    check();
  }, [user]);

  const check = () => {
    getAccessTokenSilently()
      .then((d) => {
        getIdTokenClaims()
          .then((d) => {
            hasUserIdentity((d) => {
              setStage(d);
            });
          })
          .catch((e) => {});
      })
      .catch((e) => {
        setStage({ stage: StageEnum.NOT_LOGGED, workspaces: [] });
      });
  };

  useEffect(() => {
    if (isNotNullOrUndefined(stage?.workspaces) && stage.workspaces.length > 0) {
      navigate(Page.DASHBOARD, {
        workspaceUUID: stage.workspaces.find((t) => t.isPrefered)?.uuid || stage.workspaces[0].uuid,
      });
    }
  }, [stage]);

  return (
    <AdminLayout>
      {stage.stage === StageEnum.NO_IDENTITY && <UpdateUserName onSaved={check} />}
      {stage.stage === StageEnum.NOT_LOGGED && <LoginOrRegister />}
    </AdminLayout>
  );
};

export default IndexPage;
