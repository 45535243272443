import uniqid from 'uniqid';

export const splitToBulks = <T>(arr: T[], bulkSize: number = 20): T[][] => {
  const bulks: T[][] = [];
  for (let i = 0; i < Math.ceil(arr.length / bulkSize); i++) {
    bulks.push(arr.slice(i * bulkSize, (i + 1) * bulkSize));
  }
  return bulks;
};

export const isNullOrUndefined = (value: any) => value === undefined || value === null;
export const isNotNullOrUndefined = (value: any) => !(value === undefined || value === null);

export const makeVariableSymbol = (applicationId: string) => applicationId.replace(/\D/g, '');

export const uniq = () => {
  return uniqid();
};

export const isObject = (object) => {
  return object != null && typeof object === 'object';
};

export const deepEqual = (object1, object2) => {
  if (object1 === object2) return true;
  if (isNullOrUndefined(object1)) return false;
  if (isNullOrUndefined(object2)) return false;

  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }
  return true;
};
